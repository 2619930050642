import React, { Fragment } from "react"
import SEO from "src/components/SEO"
import { GoogleMapEmbed } from "src/components/GoogleMapEmbed"
import { PageIntro } from "src/components/PageIntro"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"
import { Header } from "src/components/Header"
import { getFluidImageSrc, getFixedImageSrc } from "src/utils/images"
import { PageBlocksAlt } from "src/components/PageBlocksAlt"
import InViewMonitor from "react-inview-monitor"

const ADDRESS_FOR_MAP = "Residence Hotel Paradiso 64014 Villa Rosa di Martinsicuro (TE)"

const PageWithMap: React.FC<any> = ({ data: { mdx } }) => {
  if (!mdx.frontmatter || !mdx.frontmatter.title || !mdx.frontmatter.intro || !mdx.frontmatter.intro.title) {
    return <div>No content has been created for this page</div>
  }

  const {
    title: pageTitle,
    intro: { title, lead, content },
    headerImage,
    cardImage1,
    cardImage2,
    pageBlocksAlt,
  } = mdx.frontmatter

  return (
    <Fragment>
      <SEO title={pageTitle} description={content} />

      {/* HEADER */}
      <Header backgroundImageUrl={getFluidImageSrc(headerImage) || ""} />
      {/* INTRO */}
      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <PageIntro title={title} lead={lead}>
          {content && content.length ? content : <MDXRenderer>{mdx.body}</MDXRenderer>}
        </PageIntro>
      </InViewMonitor>

      {/* GMAP */}
      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <GoogleMapEmbed address={ADDRESS_FOR_MAP} zoomLevel={16} />
      </InViewMonitor>

      {/* ALTERNATED PAGE BLOCK */}
      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <PageBlocksAlt
          imageCard1={{
            imageUrl: getFixedImageSrc(cardImage1) || "",
            altText: title,
          }}
          imageCard2={{
            imageUrl: getFixedImageSrc(cardImage2) || "",
            altText: title,
          }}
          blocks={(pageBlocksAlt || []).map(block => ({
            title: block.title,
            text: block.text,
            image1: {
              imageUrl: getFixedImageSrc(block.image1),
              altText: block.title,
            },
            image2: {
              imageUrl: getFixedImageSrc(block.image2),
              altText: block.title,
            },
          }))}
        />
      </InViewMonitor>
    </Fragment>
  )
}

export default PageWithMap

export const query = graphql`
  query PageWithMap($locale: String!, $title: String!) {
    mdx(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale } }) {
      frontmatter {
        title
        headerImage {
          publicURL
          childImageSharp {
            fixed(width: 600, height: 300, quality: 80) {
              ...GatsbyImageSharpFixed
              srcWebp
            }
          }
        }
        intro {
          title
          lead
          content
        }
        cardImage1 {
          publicURL
          childImageSharp {
            fixed(width: 900, height: 750, quality: 90) {
              ...GatsbyImageSharpFixed
              srcWebp
            }
          }
        }
        cardImage2 {
          publicURL
          childImageSharp {
            fixed(width: 900, height: 750, quality: 90) {
              ...GatsbyImageSharpFixed
              srcWebp
            }
          }
        }
        pageBlocksAlt {
          title
          text
          image1 {
            publicURL
            childImageSharp {
              fixed(width: 900, height: 750, quality: 90) {
                ...GatsbyImageSharpFixed
                srcWebp
              }
            }
          }
          image2 {
            publicURL
            childImageSharp {
              fixed(width: 900, height: 750, quality: 90) {
                ...GatsbyImageSharpFixed
                srcWebp
              }
            }
          }
        }
      }
      body
    }
  }
`
