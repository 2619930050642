import React from "react"

interface Props {
  address: string
  zoomLevel?: number
}

export const GoogleMapEmbed: React.FC<Props> = ({ address, zoomLevel = 10 }) => (
  <div className="google-map-wrapper mb-xl">
    <iframe
      width="100%"
      height="500"
      id="gmap_canvas"
      src={`https://maps.google.com/maps?q=${encodeURI(address)}&t=&z=${zoomLevel}&ie=UTF8&iwloc=&output=embed`}
      frameBorder="0"
      scrolling="no"
      marginHeight={0}
      marginWidth={0}
      className="bg-light"
    />
  </div>
)
